<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <BaseAutocomplete
              v-model="userExpense.user_id"
              :search-function="searchUsers"
              :initial-item="userExpense.user"
              :key="userExpense.id"
              :error-messages="errors.user_id"
              :label="formMixin_getRequiredFieldLabel($t('user'))"
              item-text="full_name"
              @input="formMixin_clearErrors('user_id')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="userExpense.date"
              :error-messages="errors['date']"
              :label="formMixin_getRequiredFieldLabel($t('date'))"
              @input="formMixin_clearErrors('date')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model.number="userExpense.amount"
              :label="formMixin_getRequiredFieldLabel($t('sum_in_euros'))"
              :error-messages="errors['amount']"
              type="number"
              @input="formMixin_clearErrors('amount')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="userExpense.comment"
              :error-messages="errors['comment']"
              :label="$t('comment')"
              auto-grow
              @input="formMixin_clearErrors('comment')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!userExpense.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import userExpenseService from '../../api/user-expense-service';
import userService from '@/api/user-service';
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';

export default {
  name: 'UserExpenseForm',

  components: { BaseDatepickerInput, BaseAutocomplete },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      userExpense: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.userExpense.id ? 'edit_user_expense' : 'new_user_expense');
    },
  },

  methods: {
    searchUsers: userService.search,

    onDialogOpen() {
      this.userExpense = JSON.parse(JSON.stringify(this.formItem));
      if (this.userExpense.user) {
        this.userExpense.user = {
          id: this.userExpense.user.id,
          full_name: this.userExpense.user.person.full_name,
        };
      }
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(userExpenseService, this.userExpense);
    },
  },
};
</script>
