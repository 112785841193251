<template>
  <div>
    <v-container fluid>
      <h1 class="title">{{ $t('salary_report') }}</h1>

      <v-row>
        <v-col cols="12" sm="6" md="3" lg="2">
          <BaseDatepickerInput
            v-model="filterParams.period_starts_at"
            :label="$t('date_from')"
            :clearable="false"
            @input="updateStartDate"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="2">
          <BaseDatepickerInput
            v-model="filterParams.period_ends_at"
            :label="$t('date_to')"
            :clearable="false"
            @input="updateEndDate"
          />
        </v-col>
        <v-col v-if="$isAdmin()" cols="12" md="6" lg="2">
          <v-autocomplete
            v-model="selectedUser"
            :items="workers"
            :label="$t('roles.worker')"
            item-value="id"
            item-text="person.full_name"
            clearable
            return-object
            @input="onUserSelect"
          />
        </v-col>
        <v-col class="d-flex align-center subtitle-1" cols="12" sm="6" lg="3">
          <template v-if="!$isAdmin()">
            <v-btn
              :disabled="isLoading"
              :loading="isLoading"
              color="primary"
              large
              @click="toggleSalaryNumbersDisplay"
              >{{ isTotalSalaryDisplayed ? $t('hide_numbers') : $t('show_numbers') }}</v-btn
            >
            <span v-if="isTotalSalaryDisplayed" class="grey--text body-2 ml-2">
              {{ $t('total') }}:
              <span v-if="totalSalary">{{ round(totalSalary) }} €</span>
              <span v-else>0</span>
            </span>
          </template>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="d-flex align-center justify-sm-end">
          <SalaryReportDownload
            v-if="$isAdmin()"
            :date-from="filterParams.period_starts_at"
            :date-to="filterParams.period_ends_at"
            :user-id="filterParams.user_id"
          />
          <BasePrimaryActionButton
            v-if="canViewExpenses"
            :label="$t('create_user_expense')"
            class="ml-0 ml-md-3"
            @click="crudMixin_openForm('userExpense', newUserExpenseTemplate)"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-tabs v-if="canViewExpenses" v-model="activeTab" @change="onTabChange">
      <v-tab>
        {{ $t('chart') }}
      </v-tab>
      <v-tab>
        {{ $t('employee_expenses') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item eager>
        <div
          v-if="!isLoading"
          class="d-flex align-start align-sm-center flex-column flex-sm-row pa-3"
        >
          <v-alert
            v-if="!userSalaryArray.length"
            max-width="256"
            type="info"
            color="grey"
            dense
            outlined
          >
            {{ $t('no_data') }}
          </v-alert>
          <template v-else-if="$isAdmin()">
            <span class="mr-4">
              {{ $t('total_salary') }}:
              <strong>€{{ round(totalSalary) }}</strong>
            </span>
            <span class="mr-4">
              {{ $t('total_expenses') }}:
              <strong>€{{ round(totalExpenses) }}</strong>
            </span>
            <span>
              {{ $t('balance') }}:
              <strong>€{{ round(totalSalary - totalExpenses) }}</strong>
            </span>
            <v-spacer />

            <v-checkbox
              v-model="showSalaryInChart"
              :label="$t('show_salaries')"
              class="mt-3 mt-sm-0 mb-3 mb-sm-0 mr-4"
              hide-details
              @change="drawChart"
            />
            <v-checkbox
              v-model="showExpensesInChart"
              :label="$t('show_expenses')"
              class="mt-0"
              hide-details
              @change="drawChart"
            />
          </template>
        </div>
        <div style="height: 50vh">
          <BaseLoadingOverlay :loading="isLoading" z-index="1">
            <SalaryReportChartDatapointMenu
              :is-open="isDataPointMenuOpen"
              :user-salaries="salariesInSelectedDataPoint"
              :user-expenses="expensesInSelectedDataPoint"
              :selected-date="selectedDataPointDate"
            >
              <template v-slot:activator="{ on }">
                <canvas v-on="on" ref="chartCanvas" @click.stop />
              </template>
            </SalaryReportChartDatapointMenu>
          </BaseLoadingOverlay>
        </div>
      </v-tab-item>
      <v-tab-item>
        <UserExpenseTable
          :rows="userExpenseArray"
          :loading="$store.getters.loading['get:api/user-expenses']"
          @edit="crudMixin_openForm('userExpense', $event)"
          @delete="deleteUserExpense"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="isUserExpenseFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <UserExpenseForm
        :dialog="isUserExpenseFormOpen"
        :form-item="userExpenseFormItem"
        @create="userExpenseCreated"
        @update="userExpenseUpdated"
        @cancel="isUserExpenseFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import randomcolor from 'randomcolor';
import { addMonths, eachDayOfInterval, endOfMonth, format, startOfMonth } from 'date-fns';
import Chart from 'chart.js';
import BaseDatepickerInput from '../components/base/BaseDatepickerInput';
import orderPartService from '../api/order-part-service';
import { sleep } from '@/util/sleep';
import SalaryReportDownload from '@/components/SalaryReportDownload';
import { round } from '@/util/numbers';
import crudMixin from '@/mixins/crud-mixin';
import UserExpenseForm from '@/components/forms/UserExpenseForm';
import userExpenseService from '@/api/user-expense-service';
import UserExpenseTable from '@/components/tables/UserExpenseTable';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import userService from '@/api/user-service';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';
import SalaryReportChartDatapointMenu from '@/components/SalaryReportChartDatapointMenu';
import BaseLoadingOverlay from '@/components/base/BaseLoadingOverlay';

// not reactive variables, because in order to update the chart
// methods on chart object need to be called
let chart;

export default {
  name: 'SalaryReport',

  components: {
    BaseLoadingOverlay,
    SalaryReportChartDatapointMenu,
    BasePrimaryActionButton,
    UserExpenseTable,
    UserExpenseForm,
    SalaryReportDownload,
    BaseDatepickerInput,
  },

  mixins: [crudMixin],

  data() {
    return {
      activeTab: 'chart',
      tabs: ['chart', 'expenses'],
      filterParams: {
        period_starts_at: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        period_ends_at: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
      },
      userSalaryArray: [],
      selectedUser: null,
      isLoading: false,
      isDataPointMenuOpen: false,
      selectedDataPointDate: '',
      detailsUserIndexes: [],
      isTotalSalaryDisplayed: this.$isAdmin(),

      userExpenseArray: [],
      userExpenseFormItem: {},
      newUserExpenseTemplate: {
        date: format(new Date(), 'yyyy-MM-dd'),
      },
      isUserExpenseFormOpen: false,

      showExpensesInChart: true,
      showSalaryInChart: true,
      workers: [],

      abortController: new AbortController(), // for canceling requests
    };
  },

  computed: {
    canViewExpenses() {
      return this.$isAdmin();
    },

    chartLabels() {
      const { period_starts_at, period_ends_at } = this.filterParams;
      const dates = eachDayOfInterval({
        start: period_starts_at ? new Date(period_starts_at) : new Date(),
        end: period_ends_at ? new Date(period_ends_at) : new Date(),
      });
      return dates.map(date => format(date, 'yyyy-MM-dd'));
    },

    salariesInSelectedDataPoint() {
      const users = [];
      for (let i = 0; i < this.detailsUserIndexes.length; i++) {
        const user = this.userSalaryArray[this.detailsUserIndexes[i]];
        for (let j = 0; j < user?.salary?.length; j++) {
          const salary = user.salary[j];
          if (salary.date === this.selectedDataPointDate) {
            users.push({
              id: user.id,
              fullName: user.full_name,
              salary,
            });
          }
        }
      }
      return users;
    },

    expensesInSelectedDataPoint() {
      const users = [];
      for (let i = 0; i < this.detailsUserIndexes.length; i++) {
        // expenses datasets follow salaries datasets, hence the '- this.salariesDataSets.length'
        const user = this.transformedUserExpenses[
          this.detailsUserIndexes[i] - this.salariesDataSets.length
        ];
        const expenses = user?.expenses?.filter(e => e.date === this.selectedDataPointDate) || [];
        if (expenses.length) {
          users.push({
            id: user.id,
            fullName: user.full_name,
            total: expenses.reduce((sum, e) => sum + e.amount, 0),
            expenses,
          });
        }
      }
      return users;
    },

    transformedUserExpenses() {
      const map = {};
      for (let i = 0; i < this.userExpenseArray.length; i++) {
        const expense = this.userExpenseArray[i];
        if (!map[expense.user_id]) {
          map[expense.user_id] = {
            id: expense.user_id,
            full_name: expense.user?.person?.full_name || '',
            expenses: [],
          };
        }
        map[expense.user_id].expenses.push({
          date: expense.date,
          amount: +expense.amount,
          comment: expense.comment,
        });
      }
      return Object.values(map);
    },

    expensesDataSets() {
      if (!this.showExpensesInChart) {
        return [];
      }
      const expensesDataSets = [];
      for (let i = 0; i < this.transformedUserExpenses.length; i++) {
        const data = [];
        const user = this.transformedUserExpenses[i];
        for (let j = 0; j < this.chartLabels.length; j++) {
          const date = this.chartLabels[j];
          data.push(
            user.expenses.filter(e => e.date === date).reduce((total, e) => total + e.amount, 0),
          );
        }
        expensesDataSets.push({
          label: `${user.full_name} (${this.$t('expenses').toLowerCase()})`,
          borderColor: randomcolor({
            seed: user.id * 6, // so the color is always the same for same user. * 3 is there to make it so two users with neighboring IDs don't look too similar
          }),
          data,
          fill: false,
          pointHitRadius: 8,
          pointHoverRadius: 8,
        });
      }
      return expensesDataSets;
    },

    salariesDataSets() {
      if (!this.showSalaryInChart) {
        return [];
      }
      const salariesDataSets = [];
      for (let i = 0; i < this.userSalaryArray.length; i++) {
        const data = [];
        const user = this.userSalaryArray[i];
        for (let j = 0; j < this.chartLabels.length; j++) {
          const date = this.chartLabels[j];
          data.push(
            user.salary
              .filter(e => e.date === date)
              .reduce((total, e) => total + e.compensation, 0),
          );
        }
        salariesDataSets.push({
          label: `${user.full_name} (${this.$t('salary').toLowerCase()})`,
          borderColor: randomcolor({
            seed: user.id * 3, // so the color is always the same for same user. * 3 is there to make it so two users with neighboring IDs don't look too similar
          }),
          data,
          fill: false,
          pointHitRadius: 8,
          pointHoverRadius: 8,
        });
      }
      return salariesDataSets;
    },

    totalExpenses() {
      return this.userExpenseArray.reduce((sum, e) => +e.amount, 0);
    },

    totalSalary() {
      let total = 0;
      for (let i = 0; i < this.userSalaryArray.length; i++) {
        total += this.userSalaryArray[i].salary.reduce((sum, s) => sum + s.compensation, 0);
      }
      return total;
    },
  },

  created() {
    if (this.canViewExpenses) {
      this.getUserExpenses();
      this.setActiveTab(this.$route.params.tab);
    } else {
      this.setActiveTab('chart');
    }

    if (this.$isAdmin()) {
      userService.getSimpleList().then(res => {
        this.workers = res.data;
      });
    }
  },

  mounted() {
    this.getReport();
    window.addEventListener('click', this.hideMenu);
  },

  beforeRouteUpdate(to, from, next) {
    this.setActiveTab(to.params.tab);
    next();
  },

  beforeDestroy() {
    chart = null;
    window.removeEventListener('click', this.hideMenu);
  },

  methods: {
    round,
    searchUsers: userService.search,

    onTabChange(newIndex) {
      this.$router.push(`/salary-report/${this.tabs[newIndex]}`);
    },

    setActiveTab(tabName) {
      const activeTabIndex = this.tabs.indexOf(tabName);
      this.activeTab = activeTabIndex > -1 ? activeTabIndex : 0;
    },

    getUserSalaries() {
      return orderPartService
        .getEmployeeCompensation(this.filterParams, this.abortController.signal)
        .then(({ data }) => {
          this.userSalaryArray = data.users;
        });
    },

    async getUserExpenses() {
      const expenseParams = JSON.parse(JSON.stringify(this.filterParams));
      if (expenseParams.period_starts_at) {
        expenseParams.date_from = expenseParams.period_starts_at;
        delete expenseParams.period_starts_at;
      }
      if (expenseParams.period_ends_at) {
        expenseParams.date_to = expenseParams.period_ends_at;
        delete expenseParams.period_ends_at;
      }

      return userExpenseService
        .getAll(expenseParams, this.abortController.signal)
        .then(({ data }) => {
          this.userExpenseArray = data.data;
          if (data.last_page > 1) {
            eventBus.$emit(
              OPEN_SNACKBAR,
              this.$t('too_many_expenses_results_found').replace('{0}', data.per_page),
            );
          }
        });
    },

    userExpenseCreated(expense) {
      this.crudMixin_created('userExpense', expense);
      this.drawChart();
    },

    userExpenseUpdated(expense) {
      this.crudMixin_updated('userExpense', expense);
      this.drawChart();
    },

    deleteUserExpense(userExpense) {
      this.crudMixin_delete(userExpenseService.delete, 'userExpense', userExpense).then(() => {
        this.drawChart();
      });
    },

    async hideMenu() {
      this.isDataPointMenuOpen = false;
      await sleep(200); // to avoid no_data message flashing inside menu
      this.detailsUserIndexes = [];
      this.selectedDataPointDate = '';
    },

    async updateStartDate(date) {
      if (this.isDataPointMenuOpen) {
        await this.hideMenu();
      }
      await this.$nextTick(); // waiting for v-text-field to update it's internals
      let startDate = date;
      if (!startDate) {
        startDate = format(startOfMonth(new Date()), 'yyyy-MM-dd');
        this.$set(this.filterParams, 'period_starts_at', startDate);
      }
      if (startDate >= this.filterParams.period_ends_at) {
        this.$set(
          this.filterParams,
          'period_ends_at',
          format(addMonths(new Date(startDate), 1), 'yyyy-MM-dd'),
        );
      }
      await this.getReport();
    },

    async updateEndDate(date) {
      if (this.isDataPointMenuOpen) {
        await this.hideMenu();
      }
      await this.$nextTick(); // waiting for v-text-field to update it's internals
      let endDate = date;
      if (!endDate) {
        endDate = format(endOfMonth(new Date()), 'yyyy-MM-dd');
        this.$set(this.filterParams, 'period_ends_at', endDate);
      }
      if (endDate <= this.filterParams.period_starts_at) {
        this.$set(
          this.filterParams,
          'period_starts_at',
          format(addMonths(new Date(endDate), -1), 'yyyy-MM-dd'),
        );
      }
      await this.getReport();
    },

    onUserSelect(user) {
      this.$set(this.filterParams, 'user_id', user?.id);
      this.getReport();
    },

    async getReport() {
      if (this.isLoading) {
        this.abortController.abort();
      } else {
        this.isLoading = true;
      }
      this.abortController = new AbortController();
      const requests = [];
      requests.push(this.getUserSalaries());
      if (this.canViewExpenses) {
        requests.push(this.getUserExpenses());
      }
      Promise.all(requests)
        .then(() => {
          this.drawChart();
          this.isLoading = false;
        })
        .catch(err => {
          if (err?.message !== 'canceled') {
            // request failed for a reason other than being canceled
            this.isLoading = false;
          }
        });
    },

    drawChart() {
      if (!this.$refs.chartCanvas) {
        return;
      }
      const datasets = [...this.salariesDataSets, ...this.expensesDataSets];
      if (!chart) {
        const ctx = this.$refs.chartCanvas.getContext('2d');
        chart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.chartLabels,
            datasets,
          },
          options: {
            maintainAspectRatio: false,
            tooltips: {
              enabled: false, // per client requirements tooltip content was moved to a popover (v-menu)
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            scales: {
              yAxes: [
                {
                  display: this.isTotalSalaryDisplayed,
                },
              ],
            },
            onClick: (element, points) => {
              this.onChartPointsClick(points);
            },
          },
        });
      } else {
        chart.data.datasets = datasets;
        chart.data.labels = this.chartLabels;
        chart.update();
      }
    },

    async onChartPointsClick(points) {
      this.isDataPointMenuOpen = false;
      if (!points.length) {
        return;
      }
      const index = points[0]._index;
      this.selectedDataPointDate = this.chartLabels[index];
      this.detailsUserIndexes = points.map(point => point._datasetIndex);
      await this.$nextTick();
      this.isDataPointMenuOpen = true;
    },

    toggleSalaryNumbersDisplay() {
      this.isTotalSalaryDisplayed = !this.isTotalSalaryDisplayed;
      chart.options.scales.yAxes[0].display = this.isTotalSalaryDisplayed;
      this.drawChart();
    },
  },
};
</script>
