<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :footer-props="{
      'items-per-page-options': [50, 100, 250, 500],
    }"
    :items="rows"
    :mobile-breakpoint="0"
    :loading="loading"
    disable-sort
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="actions" :item="item" />
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import tableMixin from '../../mixins/table-mixin';

export default {
  name: 'UserExpenseTable',

  components: {
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: this.$t('user'),
          value: 'user.person.full_name',
        },
        {
          text: this.$t('date'),
          value: 'date',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('sum_in_euros'),
          value: 'amount',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('comment'),
          value: 'comment',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },
};
</script>
