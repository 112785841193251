import http from './http';

const endpoint = 'api/user-expenses';

const userExpenseService = {
  model: 'userExpense',

  getAll: (params, signal) => http.get(endpoint, { params, signal }),

  create: userExpense => http.post(endpoint, userExpense),

  update: userExpense => http.put(`${endpoint}/${userExpense.id}`, userExpense),

  delete: userExpense => http.delete(`${endpoint}/${userExpense.id}`, userExpense),
};

export default userExpenseService;
