<template>
  <v-btn
    :disabled="$store.getters.loading['api/employee-compensation/export/xlsx']"
    :loading="$store.getters.loading['api/employee-compensation/export/xlsx']"
    color="primary"
    @click="downloadReport"
  >
    <v-icon left>mdi-download</v-icon>
    {{ $t('download_report') }}
  </v-btn>
</template>

<script>
import orderPartService from '@/api/order-part-service';
import { downloadFile } from '@/util/files';

export default {
  name: 'SalaryReportDownload',

  props: {
    dateFrom: {
      type: String,
      default: '',
    },

    dateTo: {
      type: String,
      default: '',
    },

    userId: {
      type: Number,
      default: null,
    },
  },

  methods: {
    downloadReport() {
      const params = {
        period_starts_at: this.dateFrom,
        period_ends_at: this.dateTo,
        user_id: this.userId,
      };
      orderPartService.getEmployeeCompensationReport(params).then(({ data }) => {
        downloadFile(data, `${this.$t('salary_report_file')}.xlsx`);
      });
    },
  },
};
</script>
