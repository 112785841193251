<template>
  <v-menu
    :value="isOpen"
    :close-on-content-click="false"
    :close-on-click="false"
    :open-on-click="false"
    max-width="35em"
    absolute
    offset-y
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on" />
    </template>

    <div @click.stop>
      <v-card class="pa-4 body-2">
        <div>
          <strong>{{ selectedDate }}</strong>
        </div>
        <div v-if="!userSalaries.length && !userExpenses.length">
          {{ $t('no_data') }}
        </div>
        <div v-for="(user, userIndex) in userSalaries" :key="user.id">
          <div :class="{ 'mb-3': user.salary.progress.length }">
            {{ user.fullName }}. {{ $t('salary') }}: {{ round(user.salary.compensation, 4) }} €
          </div>
          <div
            v-for="(progress, progressIndex) in user.salary.progress"
            :key="progress.id"
            :class="{
              'mb-3': progressIndex < user.salary.progress.length - 1,
            }"
          >
            <router-link
              v-if="$isAdmin()"
              :to="`/orders?search=${progress.order_part_process.order_part.order.order_no}`"
              target="_blank"
              class="link"
            >
              {{ progress.order_part_process.order_part.order.order_no }}
            </router-link>
            <template v-else>
              {{ progress.order_part_process.order_part.order.order_no }}
            </template>
            {{ progress.order_part_process.process.title }}
            {{ getAmountString(progress) }},
            {{ getUpdatedAtString(progress) }}
            <div v-for="log in progress.order_part_process_change_log" :key="log.id">
              {{ getProcessChangeLogString(log) }}
            </div>
          </div>
          <div v-if="user.salary.total_pcs || user.salary.total_minutes" class="mt-3">
            <div v-if="user.salary.total_pcs">
              {{ $t('units_in_total') }}: {{ user.salary.total_pcs }}
            </div>
            <div v-if="user.salary.total_minutes">
              {{ $t('minutes_in_total') }}: {{ user.salary.total_minutes }}
            </div>
          </div>
          <v-divider v-if="userIndex < userSalaries.length - 1" class="my-3" />
        </div>

        <v-divider v-if="userSalaries.length && userExpenses.length" class="my-3" />

        <div v-for="(user, userIndex) in userExpenses" :key="user.id">
          <div class="mb-3">
            {{ user.fullName }}. {{ $t('expenses') }}: {{ round(user.total, 4) }} €
          </div>
          <div
            v-for="(expense, expenseIndex) in user.expenses"
            :key="expense.id"
            :class="{ 'mb-3': expenseIndex < user.expenses.length - 1 }"
          >
            {{ round(expense.amount, 4) }} €:
            <span :class="{ 'grey--text': !expense.comment }">{{
              expense.comment || $t('no_comment')
            }}</span>
          </div>
          <v-divider v-if="userIndex < userExpenses.length - 1" class="my-3" />
        </div>
      </v-card>
    </div>
  </v-menu>
</template>

<script>
import { format } from 'date-fns';
import { round } from '@/util/numbers';

export default {
  name: 'SalaryReportChartDatapointMenu',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedDate: {
      type: String,
      default: '',
    },
    userSalaries: {
      type: Array,
      default: () => [],
    },
    userExpenses: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    round,

    getAmountString(progress) {
      let string = '';
      const amount = +progress.amount;
      const units = progress.unit === 'minutes' ? this.$t('minute_short') : this.$t('unit_short');

      if (amount && units) {
        string += `${round(amount, 4)} ${units}`;
      }

      const amountPerUnit = +progress.order_part_process?.employee_salary_amount_per_unit;
      if (amountPerUnit) {
        string += ` x ${round(amountPerUnit, 4)}€ `;

        const totalAmount = amount && amountPerUnit ? amount * amountPerUnit : '';
        if (totalAmount) {
          string += ` = ${round(totalAmount, 4)}€`;
        }
      }
      return string;
    },

    getUpdatedAtString(progress) {
      return `${this.$t('entered').toLowerCase()} ${format(
        new Date(progress.updated_at),
        'yyyy-MM-dd HH:mm',
      )}`;
    },

    getProcessChangeLogString(log) {
      return `${log.user.person.full_name} ${this.$t('changed_status_to')} "${this.$t(
        log.status,
      ).toLowerCase()}" ${format(new Date(log.created_at), 'yyyy-MM-dd HH:mm')}`;
    },
  },
};
</script>

<style scoped></style>
